<template>
  <a-modal
    class="average-display-modal"
    :width="480"
    :title="$t('evaluation')"
    centered
    :visible="visible"
    @cancel="handleCancel"
  >
    <div class="mod">
      <span>{{ $t('orderInfo.success_confirm_ava_score_info') }}：</span>
      <span class="score">{{ avaScore }}{{ $t('orderInfo.success_confirm_ava_score_info_unit') }}</span>
    </div>

    <template slot="footer">
      <a-button type="primary" @click="batchHandleOk">{{ $t('confirm') }}</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    avaScore: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    batchHandleOk() {
      this.$emit('close')
    },
    handleCancel() {
      console.log('close dialog')
    },
  },
}
</script>

<style lang="less" scoped>
.average-display-modal {
  /deep/.ant-modal-header {
    border-bottom: 0;
  }
  /deep/.ant-modal-footer {
    border-top: 0;
  }
  /deep/.ant-modal-body {
    padding: 30px;
  }
  .mod {
    text-align: center;
    .score {
      font-weight: 700;
    }
  }
}
</style>
